import { MerryGoRound } from '@toasttab/buffet-pui-loading-indicators'
import {
  Page,
  PageContainer,
  PageBody,
  PageMain
} from '@toasttab/buffet-pui-wizard-templates'
import React from 'react'

export const LoadingState = () => {
  return (
    <Page>
      <PageContainer>
        <PageBody>
          <PageMain>
            <div className='py-[30%] self-center'>
              <MerryGoRound size='xl' />
            </div>
          </PageMain>
        </PageBody>
      </PageContainer>
    </Page>
  )
}
