import { banquetSingleSpaReact, BanquetProps } from 'banquet-runtime-modules'
import { App } from './app/App'
import { ThemeManager } from '@toasttab/banquet-theme-manager'
import { themes } from '@local/themes'
import './index.css'

const reactLifecycles = banquetSingleSpaReact({
  cssScope: 'data-udm',
  rootComponent: App,
  portalContainers: ['banquetPortalsContainer'],

  theme: new ThemeManager((props: BanquetProps) => {
    if (props.isNextBuffetTheme) {
      return themes.next
    }
    return themes.current
  }),
  React: undefined
})

export const bootstrap = reactLifecycles.bootstrap
export const mount = reactLifecycles.mount
export const unmount = reactLifecycles.unmount
export const name = 'team-user-migration-spa'

//this will automatically be done by banquetSingleSpaReact at some point.
sessionStorage.setItem(
  `sentry:${name}`,
  `'https://75d32d36f4b3b76d28eb61910687d1c0@o37442.ingest.us.sentry.io/4508138721968128'`
)
