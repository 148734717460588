import * as React from 'react'
import {
  PasswordFieldWithRequirements,
  SubmitButton
} from '@toasttab/buffet-pui-forms'
import { Form, FormikProvider, useFormik } from 'formik'
import * as Yup from 'yup'
import { useTranslation } from '@local/translations'
import {
  ContentFooter,
  ContentHeader,
  NavigationContainer,
  PageAside,
  PageMain
} from '@toasttab/buffet-pui-wizard-templates'
import { Layout } from '../layout'
import { useState } from 'react'
import { ArrowForwardIcon } from '@toasttab/buffet-pui-icons'

interface PasswordFormValues {
  password: string
}

export const PasswordView = (props: {
  onContinue: (password: string) => Promise<void>
}) => {
  const { onContinue } = props
  const { t } = useTranslation()
  const [isInProgress, setIsInProgress] = useState(false)

  const passwordSchema = Yup.object().shape({
    password: Yup.string().required(t('password.passwordIsRequired'))
  })
  const formik = useFormik<PasswordFormValues>({
    initialValues: { password: '' },
    validationSchema: passwordSchema,
    onSubmit: async (values: PasswordFormValues) => {
      setIsInProgress(true)
      await onContinue(values.password)
    }
  })

  return (
    <Layout register={true}>
      <PageMain>
        <FormikProvider value={formik}>
          <Form>
            <ContentHeader
              title={t('password.createANewPassword')}
              description={t('password.createANewPasswordToUse')}
              contentDivider={true}
            />
            <PasswordFieldWithRequirements
              label={t('password.confirmYourPassword')}
              name='password'
              placeholder={t('password.typePassword')}
            />
            <ContentFooter>
              <NavigationContainer>
                <SubmitButton
                  disabled={!formik.isValid || formik.values.password === ''}
                  isInProgress={isInProgress}
                  iconRight={<ArrowForwardIcon accessibility='decorative' />}
                  onClick={() => formik.handleSubmit()}
                  testId={'continue'}
                >
                  {t('continue')}
                </SubmitButton>
              </NavigationContainer>
            </ContentFooter>
          </Form>
        </FormikProvider>
      </PageMain>
      <PageAside></PageAside>
    </Layout>
  )
}
